<template>
	<div class="page replay-page">
		<h2 class="is-title is-inverted">{{ title }}</h2>
		<div class="page-background" :style="{ backgroundImage: background }"></div>
		<div class="page-description">{{ subtitle }}</div>
		<div v-if="description" class="replay-page-description">
			<VueMarkdown ref="article" :source="description" class="article-content content" :prerender="prerender" @rendered="rendered" />
		</div>
		<div class="iframe-wrapper"><iframe v-if="iframeUrl" allow="fullscreen" :src="iframeUrl"></iframe></div>
	</div>
</template>

<script>
	import $http from '../utils/http';
	import meta from '../utils/meta';
	import session from '../utils/session';
	import VueMarkdown from 'vue-markdown-v2';
	import { prerender } from '../utils/markdown';
	import videojs from 'video.js';

	export default {
		name: 'ReplayPage',
		components: { VueMarkdown },
		data() {
			return {
				title: null,
				subtitle: null,
				background: null,
				description: null,
				iframeUrl: null,
				session: session.state,
			};
		},
		beforeRouteEnter(to, from, next) {
			if (to.params.url) {
				$http.get(`replay-pages?url=${to.params.url}`).then(data => {
					if (data.data instanceof Array) {
						if (data.data.length === 1) {
							next(vm => vm.setData(data.data[0]));
						} else {
							next('/');
						}
					} else {
						next(vm => vm.setData(data.data));
					}
				});
			} else {
				next('/');
			}
		},
		beforeRouteUpdate(to, from, next) {
			if (to.params.url) {
				$http.get(`replay-pages?url=${to.params.url}`).then(data => {
					if (data.data instanceof Array) {
						if (data.data.length === 1) {
							this.setData(data.data[0]);
							next();
						} else {
							next('/');
						}
					} else {
						this.setData(data.data);
						next();
					}
				});
			} else {
				next('/');
			}
		},
		methods: {
			setData(data) {
				this.title = data.title;
				meta.setTitle(this.title);
				this.subtitle = data.subtitle;
				this.description = data.description;
				this.background = data.background && data.background.url ? `url('${this.$options.filters.imageUrl(data.background)}')` : null;
				if (this.session.loggedIn) {
					let token = session.generateToken();
					this.iframeUrl = data.iframe_url.replace('{token}', token);
				}
			},
			prerender: prerender,
			rendered() {
				this.$nextTick(() => {
					if (this.$refs.article) {
						let vids = this.$refs.article.$el.getElementsByTagName('video');
						if (vids) {
							for (let v of vids) {
								videojs(v);
							}
						}
					}
				});
			},
		},
	};
</script>
